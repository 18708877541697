"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.3.0
 */
'use client';

import { WorkspaceElementType } from '@integration-app/sdk';
import { useElement } from '../useElement.mjs';

function useFieldMapping(selector) {
  const {
    item: fieldMapping,
    accessor,
    refresh,
    ...rest
  } = useElement(
    WorkspaceElementType.FieldMapping,
    selector,
    (integrationApp) => selector ? integrationApp.fieldMapping(selector) : void 0
  );
  async function apply(integrationKeys) {
    const result = await accessor?.apply(integrationKeys);
    await refresh();
    return result ?? [];
  }
  async function reset() {
    await accessor?.reset();
    await refresh();
  }
  return { fieldMapping, apply, reset, refresh, accessor, ...rest };
}

export { useFieldMapping };
