"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.3.0
 */
'use client';

import { SvgIconType } from '../../SvgIcon/svg-icon-type.mjs';

class BooleanOptionsFactory {
  async getOptions({}) {
    return this.getOptionsSync();
  }
  getOptionsSync() {
    return [
      {
        label: "Yes",
        value: true,
        iconType: SvgIconType.ListOption
      },
      {
        label: "No",
        value: false,
        iconType: SvgIconType.ListOption
      }
    ];
  }
}

export { BooleanOptionsFactory };
