"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.3.0
 */
'use client';

import useSWR from 'swr';
import { useIntegrationApp } from '../contexts/integration-app-context.mjs';

function useIntegrationAppSWR(path, options) {
  const client = useIntegrationApp();
  const fetcher = async () => {
    const response = await client.get(path, options);
    return response;
  };
  return useSWR(client ? path : void 0, fetcher, options);
}

export { useIntegrationAppSWR };
