"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.3.0
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import { clsx } from 'clsx';
import { Input } from './Input.mjs';
import classes from './SearchInput.module.css.mjs';

const SearchInput = forwardRef(
  ({ className, ...props }, ref) => {
    return /* @__PURE__ */ jsx(
      Input,
      {
        id: "search-input",
        className: clsx(className, classes["uikit-searchInput"]),
        type: "search",
        ...props,
        ref
      }
    );
  }
);

export { SearchInput };
