"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.3.0
 */
'use client';

import { useElements } from '../useElements.mjs';

function useCustomers(query) {
  const { ...rest } = useElements("customers", query);
  return {
    customers: rest.items,
    ...rest
  };
}

export { useCustomers };
