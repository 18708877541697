import { Portal } from '@ark-ui/react'
import { DropdownPortalBoundary } from '@integration-app/react'
import { TbX } from 'react-icons/tb'

import { IconButton } from 'ui-kit/icon-button'
import { Popup as UiKitPopup } from 'ui-kit/popup'

import type { ComponentProps } from 'styled-system/types'

function Root({ children, ...props }: UiKitPopup.RootProps) {
  return (
    <UiKitPopup.Root lazyMount unmountOnExit {...props}>
      {children}
    </UiKitPopup.Root>
  )
}

function Window({
  children,
  usePortal,
  hideCloseButton,
  zIndex,
  ...props
}: ComponentProps<typeof UiKitPopup.Window> & {
  usePortal?: boolean
  hideCloseButton?: boolean
}) {
  return (
    <Portal disabled={!usePortal}>
      <UiKitPopup.Backdrop zIndex={zIndex} />
      <UiKitPopup.Positioner zIndex={zIndex}>
        <DropdownPortalBoundary>
          <UiKitPopup.Window
            display='grid'
            gridTemplateAreas='"header" "content" "footer"'
            gridTemplateRows='min-content 1fr min-content'
            gap={0}
            {...props}
          >
            {children}
            {!hideCloseButton && (
              <UiKitPopup.CloseTrigger
                asChild
                position='absolute'
                top='2'
                right='3'
                zIndex='docked'
              >
                <IconButton aria-label='Close Dialog' variant='ghost' size='xs'>
                  <TbX />
                </IconButton>
              </UiKitPopup.CloseTrigger>
            )}
          </UiKitPopup.Window>
        </DropdownPortalBoundary>
      </UiKitPopup.Positioner>
    </Portal>
  )
}

function Header({
  children,
  ...props
}: ComponentProps<typeof UiKitPopup.Header>) {
  return (
    <UiKitPopup.Header
      gridArea='header'
      paddingInline='5'
      paddingBlock='3'
      borderBlockEndWidth='1'
      borderBlockEndColor='border.subtle'
      {...props}
    >
      {children}
    </UiKitPopup.Header>
  )
}

function Content({
  children,
  ...props
}: ComponentProps<typeof UiKitPopup.Content>) {
  return (
    <UiKitPopup.Content
      gridArea='content'
      overflowY='auto'
      paddingInline='5'
      paddingBlock='4'
      {...props}
    >
      {children}
    </UiKitPopup.Content>
  )
}

function Footer({
  children,
  ...props
}: ComponentProps<typeof UiKitPopup.Footer>) {
  return (
    <UiKitPopup.Footer
      gridArea='footer'
      display='flex'
      justifyContent='flex-end'
      alignItems='center'
      gap={'4'}
      borderBlockStartWidth='1'
      borderBlockStartColor='border.subtle'
      paddingInline='5'
      paddingBlock='3'
      {...props}
    >
      {children}
    </UiKitPopup.Footer>
  )
}

const Popup = {
  Root,
  Window,
  Header,
  Content,
  Footer,
  Trigger: UiKitPopup.Trigger,
  CloseTrigger: UiKitPopup.CloseTrigger,
  Title: UiKitPopup.Title,
  Description: UiKitPopup.Description,
  Context: UiKitPopup.Context,
}
export { Popup }
