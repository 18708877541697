"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.3.0
 */
'use client';

import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import { SvgIconType } from '../../SvgIcon/svg-icon-type.mjs';
import { makeDataField } from '../index.mjs';
import { Formula, Variable, VariableSvgIcon } from '../../Formula/index.mjs';
import useDataBuilderField from '../field-context.mjs';
import { DataBuilderForm } from '../Form.mjs';
import { ValueMapping } from '../Nested/ValueMapping.mjs';

const MapFormula = forwardRef(
  (props, ref) => {
    return /* @__PURE__ */ jsx(Formula, { ref, onClick: props.onClick, children: /* @__PURE__ */ jsxs(Variable, { children: [
      /* @__PURE__ */ jsx(VariableSvgIcon, { type: SvgIconType.Function }),
      " Map values"
    ] }) });
  }
);
MapFormula.displayName = "MapFormula";
function MapFormulaDropdown({
  formula,
  onChange,
  variablesSchema
}) {
  const { field } = useDataBuilderField();
  const mappingField = {
    ...field,
    value: { $map: formula.args ?? {} }
  };
  function handleValueChange(value) {
    onChange?.({
      $map: {
        ...formula.args ?? {},
        value
      }
    });
  }
  function handleMappingChange(value) {
    onChange?.({
      $map: {
        ...formula.args ?? {},
        mapping: value
      }
    });
  }
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      DataBuilderForm,
      {
        field: makeDataField({
          schema: { type: "string", title: "Source Value" },
          value: formula.args?.value,
          variablesSchema
        }),
        onChange: handleValueChange
      }
    ),
    /* @__PURE__ */ jsx(ValueMapping, { field: mappingField, onChange: handleMappingChange })
  ] });
}

export { MapFormulaDropdown, MapFormula as default };
