"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.3.0
 */
'use client';

import { WorkspaceElementType } from '@integration-app/sdk';
import { useElement } from '../useElement.mjs';

function useAppEventSubscription(selector) {
  const { item: appEventSubscription, ...rest } = useElement(
    WorkspaceElementType.AppEventSubscription,
    selector,
    (integrationApp) => selector ? integrationApp.appEventSubscription(selector) : void 0
  );
  return { appEventSubscription, ...rest };
}

export { useAppEventSubscription };
