"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.3.0
 */
'use client';

import { jsxs, jsx, Fragment } from 'react/jsx-runtime';
import '../../../../../styled-system/css/css.mjs';
import '../../../../../styled-system/helpers.mjs';
import { sva } from '../../../../../styled-system/css/sva.mjs';
import 'react';
import '../../../../../styled-system/jsx/is-valid-prop.mjs';
import { Flex } from '../../../../../styled-system/jsx/flex.mjs';
import '../../../../../styled-system/patterns/visually-hidden.mjs';
import { Badge } from '../../../../../ui-kit/styled/badge.mjs';
import { Button } from '../../../../../ui-kit/button.mjs';
import { Text } from '../../../../../ui-kit/styled/text.mjs';

const panelHeaderRecipe = sva({
  className: "panel-header",
  slots: ["container", "title", "actions", "edit-button"],
  base: {
    container: {
      justifyContent: "space-between",
      alignItems: "center"
    },
    title: {
      textStyle: "xs",
      color: "slate.11",
      fontWeight: "medium",
      margin: "0"
    },
    actions: {
      gap: "2"
    },
    "edit-button": {
      color: "slate.11",
      fontWeight: "medium",
      rounded: "sm",
      py: "0.5",
      px: "1",
      gap: "0.5",
      h: "initial"
    }
  }
});
function PanelHeader({
  title,
  actions
}) {
  const {
    container,
    title: titleStyles,
    actions: actionsStyles
  } = panelHeaderRecipe();
  return /* @__PURE__ */ jsxs(Flex, { className: container, children: [
    /* @__PURE__ */ jsx("h3", { className: titleStyles, children: title }),
    /* @__PURE__ */ jsx(Flex, { className: actionsStyles, children: actions })
  ] });
}
function PanelHeaderButton({
  children,
  ...props
}) {
  const styles = panelHeaderRecipe();
  return /* @__PURE__ */ jsx(
    Button,
    {
      size: "xs",
      variant: "ghost",
      colorPalette: "gray",
      className: styles["edit-button"],
      ...props,
      children
    }
  );
}
const parametersPreviewRecipe = sva({
  className: "parameters-preview",
  slots: ["container", "no-params", "badge"],
  base: {
    container: {
      gap: "2",
      flexWrap: "wrap"
    },
    "no-params": {
      color: "slate.11",
      textStyle: "sm",
      fontWeight: "medium"
    },
    badge: {
      color: "slate.11"
    }
  }
});
function ParametersList({ children }) {
  const styles = parametersPreviewRecipe();
  return /* @__PURE__ */ jsx(Flex, { className: styles.container, children });
}
function Parameter({ children }) {
  const styles = parametersPreviewRecipe();
  return /* @__PURE__ */ jsx(
    Badge,
    {
      size: "sm",
      variant: "subtle",
      colorPalette: "gray",
      className: styles.badge,
      children
    }
  );
}
function NoParameters() {
  const styles = parametersPreviewRecipe();
  return /* @__PURE__ */ jsx(Text, { as: "span", className: styles["no-params"], children: "No parameters provided" });
}
function ParameterText({
  title,
  value
}) {
  if (!value) return /* @__PURE__ */ jsx(Fragment, { children: title });
  const truncateTo = 20;
  const truncatedValue = value.length > truncateTo ? `${value?.substring(0, truncateTo)}...` : value;
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    title,
    ": ",
    truncatedValue
  ] });
}

export { NoParameters, PanelHeader, PanelHeaderButton, Parameter, ParameterText, ParametersList };
