"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.3.0
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import { clsx } from 'clsx';
import classes from './FieldControlPanel.module.css.mjs';

function FieldControlPanelRoot({
  children,
  compact,
  noWrap
}) {
  return /* @__PURE__ */ jsx(
    "div",
    {
      className: clsx(
        classes.panel,
        compact && classes.panel__compact,
        noWrap && classes.panel__noWrap
      ),
      children
    }
  );
}
function FieldControlPanelText({ children }) {
  return /* @__PURE__ */ jsx("span", { className: classes.panel_text, children });
}
function FieldControlButtonGroup({ children }) {
  return /* @__PURE__ */ jsx("div", { className: classes.buttonGroup, children });
}
const FieldControlButtonElement = forwardRef(({ children, className, ...props }, ref) => {
  return /* @__PURE__ */ jsx("button", { ref, className: clsx(classes.button, className), ...props, children });
});
const FieldControlPanel = Object.assign(FieldControlPanelRoot, {
  Text: FieldControlPanelText,
  ButtonGroup: FieldControlButtonGroup,
  Button: FieldControlButtonElement
});

export { FieldControlPanel };
