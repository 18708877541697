import {
  NavLink as ReactRouterLink,
  NavLinkProps as ReactRouterLinkProps,
} from 'react-router-dom'

import { Link as UiLink, LinkProps as UiLinkProps } from 'ui-kit/link'

const reactRouterProps = [
  'replace',
  'state',
  'to',
  'end',
  'preventScrollReset',
  'relative',
  'viewTransition',
  'caseSensitive',
] as const

type RRPropsKeys = (typeof reactRouterProps)[number]
type RRProps = Pick<ReactRouterLinkProps, RRPropsKeys>

export function Link({ children, to, ...props }: UiLinkProps & RRProps) {
  const [styleProps, linkProps] = splitLinkProps(props)
  return (
    <UiLink asChild {...styleProps}>
      <ReactRouterLink to={to} {...linkProps}>
        {children}
      </ReactRouterLink>
    </UiLink>
  )
}

function splitLinkProps(
  props: Partial<RRProps> & UiLinkProps,
): [styleProps: UiLinkProps, linkProps: Partial<RRProps>] {
  const styleProps = {}
  const linkProps = {}

  for (const key in props) {
    if (reactRouterProps.includes(key as any)) {
      linkProps[key] = props[key]
    } else {
      styleProps[key] = props[key]
    }
  }

  return [styleProps, linkProps]
}
