"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.3.0
 */
'use client';

import { WorkspaceElementType } from '@integration-app/sdk';
import { useElement } from '../useElement.mjs';

function useConnection(id) {
  const { item: connection, ...rest } = useElement(
    WorkspaceElementType.Connection,
    id,
    (integrationApp) => id ? integrationApp.connection(id) : void 0
  );
  return {
    connection,
    ...rest
  };
}

export { useConnection };
