"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.3.0
 */
'use client';

import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import { useState, useEffect } from 'react';
import { DEFAULT_FIELD_KEY, isRootField } from '../data-field.mjs';
import DataBuilderFormField from '../Field.mjs';
import { DataBuilderFieldControlPanel } from './FieldControlPanel/index.mjs';
import { FieldListWrapper } from './FieldListWrapper/index.mjs';
import useDataBuilder from '../data-builder-context.mjs';

const MIN_FIELDS_VISIBLE_BY_DEFAULT = 7;
function DataBuilderFormNestedObjectFields({
  field,
  onChange
}) {
  const { showNullValueFields, hideReadOnlyFields } = useDataBuilder();
  const [fieldToShowKeys, setFieldToShowKeys] = useState(
    getInitialFieldsToShow(field)
  );
  useEffect(() => {
    if (field?.subFields) {
      setFieldToShowKeys(getInitialFieldsToShow(field));
    }
  }, [field?.subFields?.length]);
  function handleNewValue(newValue) {
    if (Object.keys(newValue ?? {}).length > 0) {
      onChange(newValue);
    } else {
      onChange(void 0);
    }
  }
  function handlePropertyKeyChange(oldKey, newKey) {
    const newProperties = {};
    for (const [key, value] of Object.entries(field.value)) {
      if (key == oldKey) {
        newProperties[newKey] = value;
      } else {
        newProperties[key] = value;
      }
    }
    handleNewValue(newProperties);
    setFieldToShowKeys(
      fieldToShowKeys.map((key) => key === oldKey ? newKey : key)
    );
  }
  function handlePropertyValueChange(key, value) {
    const newProperties = { ...field.value };
    newProperties[key] = value;
    handleNewValue(newProperties);
  }
  function handleAddProperty(fieldKey = DEFAULT_FIELD_KEY) {
    handleNewValue({
      ...field.value || {},
      [fieldKey]: null
    });
    setFieldToShowKeys([...fieldToShowKeys, fieldKey]);
  }
  function handleDeleteProperty(propertyField) {
    const newValue = { ...field.value || {} };
    delete newValue[propertyField.key];
    handleNewValue(newValue);
    setFieldToShowKeys(fieldToShowKeys.filter((x) => x !== propertyField.key));
  }
  function isFieldHidden(field2) {
    return hideReadOnlyFields && field2?.schema?.readOnly;
  }
  function isFieldDisplayedByDefault(field2) {
    const isFieldRequired = field2?.isRequired;
    const isFieldFilled = !!field2?.value || showNullValueFields && field2?.value === null;
    const isFieldToShow = field2?.key !== void 0 ? fieldToShowKeys.includes(field2.key) : false;
    return isFieldRequired || isFieldFilled || isFieldToShow;
  }
  function sortFieldByFieldToShowKey(fieldA, fieldB) {
    const fieldAIndex = fieldToShowKeys.indexOf(fieldA.key);
    const fieldBIndex = fieldToShowKeys.indexOf(fieldB.key);
    if (fieldAIndex !== -1 && fieldBIndex !== -1) {
      if (fieldAIndex === -1 || fieldAIndex < fieldBIndex) return -1;
      if (fieldBIndex === -1 || fieldAIndex > fieldBIndex) return 1;
    }
    return 0;
  }
  const baseFields = (field?.subFields ?? []).filter((f) => !isFieldHidden(f)).filter(isFieldDisplayedByDefault).sort(sortFieldByFieldToShowKey);
  const additionalFields = (field?.subFields ?? []).filter((f) => !isFieldHidden(f)).filter((x) => !isFieldDisplayedByDefault(x));
  return /* @__PURE__ */ jsx(
    FieldListCollapsible,
    {
      field,
      baseFields,
      additionalFields,
      onChange: handlePropertyValueChange,
      onKeyChange: handlePropertyKeyChange,
      onDelete: handleDeleteProperty,
      onAdd: handleAddProperty
    }
  );
}
function getInitialFieldsToShow(field) {
  const subFields = field?.subFields ?? [];
  const getKeysFromSubFields = (subFields2) => {
    return subFields2.map((x) => x.key).filter((x) => x !== void 0);
  };
  const requiredFieldKeys = getKeysFromSubFields(
    subFields.filter((x) => x.isRequired)
  );
  const fieldsWithValueKeys = getKeysFromSubFields(
    subFields.filter((x) => x.value !== void 0)
  );
  const minimalVisibleSetOfFields = getKeysFromSubFields(
    subFields.filter((_, index) => index < MIN_FIELDS_VISIBLE_BY_DEFAULT)
  );
  const allUniqueFields = [
    .../* @__PURE__ */ new Set([
      ...requiredFieldKeys,
      ...fieldsWithValueKeys,
      ...minimalVisibleSetOfFields
    ])
  ];
  const amountFieldsToShow = allUniqueFields.length;
  if (isRootField(field) && amountFieldsToShow === 0) {
    return getKeysFromSubFields(subFields);
  }
  return allUniqueFields;
}
function FieldListCollapsible({
  field,
  baseFields,
  additionalFields,
  onChange,
  onKeyChange,
  onDelete,
  onAdd
}) {
  const { collapsedDataBuilder, initialVisibleFieldCount } = useDataBuilder();
  const isRoot = field.level === 1;
  const showCollapseWrapper = isRoot && collapsedDataBuilder && baseFields.length > initialVisibleFieldCount;
  const [collapsed, setCollapsed] = useState(isRoot);
  function toggleCollapsed() {
    setCollapsed(!collapsed);
  }
  if (!isRoot || !showCollapseWrapper) {
    return /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(
        BaseFieldsList,
        {
          baseFields,
          onChange,
          onKeyChange,
          onAdd,
          onDelete
        }
      ),
      /* @__PURE__ */ jsx(
        DataBuilderFieldControlPanel,
        {
          field,
          onAdd,
          additionalFields
        }
      )
    ] });
  }
  const renderedFields = collapsed ? baseFields.slice(0, initialVisibleFieldCount) : baseFields;
  const fieldsToShow = baseFields.length - initialVisibleFieldCount;
  return /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsxs(
    FieldListWrapper,
    {
      collapsed,
      onClick: toggleCollapsed,
      fieldsToShow,
      children: [
        /* @__PURE__ */ jsx(
          BaseFieldsList,
          {
            baseFields: renderedFields,
            onChange,
            onKeyChange,
            onAdd,
            onDelete
          }
        ),
        !collapsed && /* @__PURE__ */ jsx(
          DataBuilderFieldControlPanel,
          {
            field,
            onAdd,
            additionalFields
          }
        )
      ]
    }
  ) });
}
function BaseFieldsList({
  baseFields,
  onChange,
  onKeyChange,
  onDelete,
  onAdd
}) {
  return /* @__PURE__ */ jsx(Fragment, { children: baseFields.map((field, idx) => /* @__PURE__ */ jsx(
    DataBuilderFormField,
    {
      field,
      onChange: (value) => onChange(field.key, value),
      onKeyChange: (newKey) => onKeyChange(field.key, newKey),
      onDelete: () => onDelete(field),
      onEnter: onAdd
    },
    idx
  )) });
}

export { DataBuilderFormNestedObjectFields as default };
