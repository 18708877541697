"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.3.0
 */
'use client';

import { EditorTagNode } from '../nodes/EditorTagNode.mjs';
import { transformValueToTpl, getTemplateFromTplValue, getValuesFromTplValue, splitStringToChunks, isTagChunk, getTagChunkIdentifier, isValueTemplate } from './common.mjs';

function transformValueToEditorSchema(value) {
  const paragraph = {
    children: getParagraphNodes(value),
    direction: "ltr",
    format: "",
    indent: 0,
    textFormat: 0,
    textStyle: "",
    type: "paragraph",
    version: 1
  };
  return {
    root: {
      children: [paragraph],
      direction: "ltr",
      format: "",
      indent: 0,
      type: "root",
      version: 1
    }
  };
}
const getParagraphNodes = (value) => {
  const safeValue = transformValueToTpl(value);
  const template = getTemplateFromTplValue(safeValue);
  const templateValues = getValuesFromTplValue(safeValue);
  const chunks = splitStringToChunks(template);
  if (!chunks.length) {
    return [];
  }
  const nodes = chunks.map(
    (chunk) => {
      if (isTagChunk(chunk)) {
        const tagIdentifier = getTagChunkIdentifier(chunk);
        const value2 = templateValues[tagIdentifier];
        if (value2) {
          return {
            type: EditorTagNode.getType(),
            value: { value: value2, autoOpen: false, id: tagIdentifier },
            version: 1
          };
        }
      }
      return {
        type: "text",
        version: 1,
        detail: 0,
        format: 0,
        mode: "normal",
        style: "",
        text: chunk
      };
    }
  );
  const valueHasTags = nodes.some(
    (node) => node.type === EditorTagNode.getType()
  );
  return valueHasTags ? nodes : [
    {
      type: "text",
      version: 1,
      detail: 0,
      format: 0,
      mode: "normal",
      style: "",
      text: isValueTemplate(value) ? value.$tpl.template : value
    }
  ];
};

export { transformValueToEditorSchema };
