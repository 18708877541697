"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.3.0
 */
'use client';

import { forwardRef, createElement } from 'react';
import { splitProps } from '../helpers.mjs';
import { getDividerStyle } from '../patterns/divider.mjs';
import { styled } from './factory.mjs';

const Divider = /* @__PURE__ */ forwardRef(function Divider2(props, ref) {
  const [patternProps, restProps] = splitProps(props, ["orientation", "thickness", "color"]);
  const styleProps = getDividerStyle(patternProps);
  const mergedProps = { ref, ...styleProps, ...restProps };
  return createElement(styled.div, mergedProps);
});

export { Divider };
