"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.3.0
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { forwardRef, useRef, useEffect } from 'react';
import { clsx } from 'clsx';
import classes from './Textarea.module.css.mjs';

const CssPropTextareaMaxHeight = "--uikit-textarea-max-height";
const Textarea = forwardRef(
  ({ autoHeight, ...props }, ref) => {
    if (autoHeight) {
      return /* @__PURE__ */ jsx(
        TextareaWithAutoHeight,
        {
          ref,
          ...props
        }
      );
    }
    return /* @__PURE__ */ jsx(
      GenericTextarea,
      {
        ref,
        ...props
      }
    );
  }
);
Textarea.displayName = "Textarea";
const GenericTextarea = forwardRef(
  ({ className, variant, children, ...props }, ref) => {
    return /* @__PURE__ */ jsx(
      "textarea",
      {
        ref,
        className: clsx(
          classes["uikit-textarea"],
          getClassNameByVariant(variant),
          className
        ),
        ...props,
        children
      }
    );
  }
);
const TextareaWithAutoHeight = forwardRef(({ maxHeight, ...props }, ref) => {
  const refWrapper = useRef(null);
  const handleOnInput = (e) => {
    const wrapper = refWrapper.current;
    if (wrapper) {
      wrapper.dataset.replicatedValue = e.target.value;
    }
  };
  useEffect(() => {
    const maxHeightValue = typeof maxHeight === "number" ? `${maxHeight}px` : maxHeight;
    refWrapper?.current?.style.setProperty(
      CssPropTextareaMaxHeight,
      `${maxHeightValue}`
    );
  }, [maxHeight]);
  return /* @__PURE__ */ jsx("div", { ref: refWrapper, className: classes.textareaWrapper, children: /* @__PURE__ */ jsx(GenericTextarea, { ref, onInput: handleOnInput, ...props }) });
});
function getClassNameByVariant(variant) {
  switch (variant) {
    case "background":
      return classes["uikit-textarea__background"];
    case "transparent":
      return classes["uikit-textarea__transparent"];
    case "border":
    default:
      return classes["uikit-textarea__border"];
  }
}

export { Textarea };
