"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.3.0
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import { clsx } from 'clsx';
import { dropdownRecipe } from '../../recipes/dropdown.mjs';

const ListHeader = forwardRef(({ children, className, ...props }, ref) => {
  const styles = dropdownRecipe({});
  return /* @__PURE__ */ jsx(
    "span",
    {
      className: clsx(styles["items-list-header"], className),
      role: "heading",
      "aria-level": 2,
      ref,
      ...props,
      children
    }
  );
});

export { ListHeader };
