"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.3.0
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { DropdownUI } from '../../../DropdownUI/index.mjs';
import { OptionsList } from '../OptionsList.mjs';

function CustomValueCategoryContent({
  onOptionSelect
}) {
  return /* @__PURE__ */ jsx(DropdownUI.Panel, { children: /* @__PURE__ */ jsx(OptionsList, { onOptionSelect }) });
}

export { CustomValueCategoryContent };
