"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.3.0
 */
'use client';

import AwesomeDebouncePromiseImport from 'awesome-debounce-promise';

const AwesomeDebouncePromise = (
  // @ts-ignore
  AwesomeDebouncePromiseImport?.default ?? AwesomeDebouncePromiseImport
);

export { AwesomeDebouncePromise as default };
