"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.3.0
 */
'use client';

import '../styled-system/css/css.mjs';
import '../styled-system/helpers.mjs';
import { sva } from '../styled-system/css/sva.mjs';

const SCOPE = "dropdown";
const SLOTS = [
  [
    "popover-container",
    "Main container of a dropdown. Emits keyboard navigation events"
  ],
  ["content", "Dropdown content container"],
  ["panel", "Section panel. Can be multiple. Can be nested"],
  ["search-input", "Search input element"],
  ["search-divider", "Divider between search input and rest of elements"],
  ["list", "Items list container"],
  ["items-list-header", "Items list header"],
  ["item", "Single list item container"],
  ["item-title", "List item title"],
  ["no-options-heading", "Heading title for empty search result"],
  ["error-heading", "Heading for error when options failed to load"],
  ["error-heading-icon", "Error heading icon"],
  ["info-heading", "Heading for unsupported states within the dropdown"],
  ["info-heading-icon", "Info heading icon"],
  ["loading-item-container", "List item skeleton container"],
  ["loading-item-icon", "List item icon skeleton"],
  ["loading-item-title", "Loading item title skeleton"]
];
const extractedSlotNames = SLOTS.map(([slot]) => slot);
const svaConfig = {
  className: SCOPE,
  slots: extractedSlotNames,
  base: {
    "popover-container": {
      outline: "none",
      display: "flex",
      overflow: "hidden",
      background: "white",
      boxShadow: "xl",
      rounded: "lg",
      borderColor: "gray.2",
      borderWidth: "0.5",
      padding: "0",
      maxW: "none",
      maxH: "var(--available-height)",
      h: "full",
      w: "fit-content"
    },
    content: {
      display: "flex",
      flexDir: "column",
      p: "1.5",
      w: "full",
      minW: "56",
      maxW: "min(65em, 90vw)",
      maxH: "inherit"
    },
    panel: {
      display: "flex",
      flexDir: "column",
      overflow: "hidden",
      pb: "1.5",
      minH: "8",
      _last: {
        pb: "0"
      }
    },
    "search-input": {
      w: "full",
      rounded: "none",
      border: "none",
      px: "2",
      py: "1.5",
      textStyle: "md",
      fontWeight: "medium",
      _placeholder: {}
    },
    "search-divider": {
      pt: "0.5",
      pb: "1.5"
    },
    list: {
      overflowY: "auto",
      position: "relative",
      flexGrow: "1",
      minH: "8",
      maxW: "md"
    },
    item: {
      display: "flex",
      flexDir: "row",
      gap: "1.5",
      alignItems: "center",
      px: "2",
      py: "1",
      cursor: "pointer",
      minH: "6",
      _hover: {
        bg: "slate.3"
      },
      _current: {
        bg: "slate.3"
      },
      rounded: "md"
    },
    "no-options-heading": {
      display: "flex",
      flexDir: "row",
      gap: "1.5",
      alignItems: "center",
      px: "2",
      py: "1",
      minH: "6",
      color: "slate.10",
      fontWeight: "medium",
      textStyle: "sm"
    },
    "error-heading": {
      display: "flex",
      flexDir: "row",
      gap: "1.5",
      alignItems: "center",
      px: "2",
      py: "1",
      minH: "6",
      fontWeight: "medium",
      textStyle: "sm"
    },
    "error-heading-icon": {
      color: "tomato.10",
      h: "7",
      w: "7"
    },
    "info-heading": {
      display: "flex",
      flexDir: "row",
      gap: "1.5",
      alignItems: "center",
      px: "2",
      py: "1",
      minH: "6",
      fontWeight: "medium",
      textStyle: "sm"
    },
    "info-heading-icon": {
      color: "fg.default",
      h: "7",
      w: "7"
    },
    "loading-item-container": {
      display: "flex",
      flexDir: "row",
      gap: "1.5",
      alignItems: "center",
      px: "2",
      py: "1"
    },
    "loading-item-icon": {
      h: "6",
      w: "6",
      rounded: "md"
    },
    "loading-item-title": {
      h: "5",
      w: "3/5",
      rounded: "md"
    },
    "items-list-header": {
      display: "flex",
      p: "2",
      color: "slate.11",
      textTransform: "uppercase",
      fontSize: "xs",
      fontWeight: "medium",
      letterSpacing: "wide",
      position: "sticky",
      top: "0",
      zIndex: "docked",
      minH: "auto",
      pointerEvents: "none",
      background: "white"
    },
    "item-title": {
      display: "flex",
      flexGrow: "1",
      flexDir: "row",
      alignItems: "baseline",
      gap: "1",
      maxW: "full",
      overflowWrap: "break-word",
      textStyle: "sm",
      fontWeight: "medium"
    }
  }
};
const recipe = sva(svaConfig);
const dropdownRecipe = Object.assign(recipe, {
  SCOPE,
  DESCRIPTION: "Dropdown component",
  SLOTS
});

export { dropdownRecipe };
