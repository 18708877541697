"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.3.0
 */
'use client';

function convertableSchemaToSchema(oldSchemaType, newSchemaType) {
  if (oldSchemaType === newSchemaType) {
    return true;
  }
  if ([oldSchemaType, newSchemaType].includes("object") || [oldSchemaType, newSchemaType].includes("array")) {
    return false;
  }
  return true;
}

export { convertableSchemaToSchema };
