"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.3.0
 */
'use client';

import { jsx, jsxs } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import { schemaWithTitle } from '@integration-app/sdk';
import { DrilldownBlockContentWrapper } from '../../DataBuilderUI/index.mjs';
import { DataInput } from '../../DataInput/index.mjs';
import { Formula, Variable, VariableSvgIcon } from '../../Formula/index.mjs';
import { SvgIconType } from '../../SvgIcon/svg-icon-type.mjs';
import useDataBuilderField from '../field-context.mjs';

const MergeObjectsFormula = forwardRef(
  (props, ref) => {
    return /* @__PURE__ */ jsx(Formula, { ref, onClick: props.onClick, children: /* @__PURE__ */ jsxs(Variable, { children: [
      /* @__PURE__ */ jsx(VariableSvgIcon, { type: SvgIconType.Function }),
      " Merge objects"
    ] }) });
  }
);
MergeObjectsFormula.displayName = "MergeObjectsFormula";
function MergeObjectsDropdown({
  onChange,
  formula
}) {
  const { field } = useDataBuilderField();
  return /* @__PURE__ */ jsx(DrilldownBlockContentWrapper, { children: /* @__PURE__ */ jsx(
    DataInput,
    {
      schema: schemaWithTitle(
        {
          type: "array",
          items: field.schema
        },
        "Objects to Merge"
      ),
      value: formula.args,
      variablesSchema: field.variablesSchema,
      onChange: (value) => {
        onChange?.({ $mergeObjects: value });
      }
    }
  ) });
}

export { MergeObjectsDropdown, MergeObjectsFormula as default };
