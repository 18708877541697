"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.3.0
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { makeDataField } from '../DataBuilder/index.mjs';
import { DataBuilderForm } from '../DataBuilder/Form.mjs';

function DataInput({
  schema,
  variablesSchema,
  value,
  onChange,
  readOnly,
  editableVariablesSchemaLocators,
  onAddVariable,
  isAdminMode,
  errorFieldsLocators,
  frozenFieldsLocators,
  showNullValueFields,
  hideReadOnlyFields
}) {
  const field = makeDataField({
    schema,
    variablesSchema,
    value
  });
  return /* @__PURE__ */ jsx(
    DataBuilderForm,
    {
      field,
      isAdminMode,
      readOnly,
      onChange,
      editableVariablesSchemaLocators,
      onAddVariable,
      errorFieldsLocators,
      frozenFieldsLocators,
      showNullValueFields,
      hideReadOnlyFields
    }
  );
}

export { DataInput };
