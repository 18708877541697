"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.3.0
 */
'use client';

import { getPatternStyles, patternFns } from '../helpers.mjs';
import '../css/css.mjs';

const boxConfig = {
  transform(props) {
    return props;
  }
};
const getBoxStyle = (styles = {}) => {
  const _styles = getPatternStyles(boxConfig, styles);
  return boxConfig.transform(_styles, patternFns);
};

export { getBoxStyle };
