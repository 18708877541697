"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.3.0
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import { clsx } from 'clsx';
import { dropdownRecipe } from '../../recipes/dropdown.mjs';
import '../../styled-system/css/css.mjs';
import '../../styled-system/helpers.mjs';
import '../../styled-system/jsx/is-valid-prop.mjs';
import { Box } from '../../styled-system/jsx/box.mjs';
import '../../styled-system/patterns/visually-hidden.mjs';
import { scrollShadow } from './sva-recipe.mjs';

const List = forwardRef(
  ({ children, className, ...props }, ref) => {
    const { list } = dropdownRecipe();
    const sc = scrollShadow();
    return /* @__PURE__ */ jsx(
      Box,
      {
        role: "list",
        "aria-label": "Options",
        className: clsx(list, sc, className),
        ...props,
        ref,
        children
      }
    );
  }
);

export { List };
