"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.3.0
 */
'use client';

import { jsxs, jsx } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import '../../styled-system/css/css.mjs';
import '../../styled-system/helpers.mjs';
import '../../styled-system/jsx/is-valid-prop.mjs';
import { Flex } from '../../styled-system/jsx/flex.mjs';
import { Circle } from '../../styled-system/jsx/circle.mjs';
import '../../styled-system/patterns/visually-hidden.mjs';
import { Skeleton } from '../../ui-kit/styled/skeleton.mjs';

const IntegrationItemSkeleton = forwardRef(({ ...props }, ref) => {
  return /* @__PURE__ */ jsxs(
    Flex,
    {
      ref,
      display: "flex",
      bg: "white",
      justifyContent: "start",
      alignItems: "center",
      gap: "2",
      flexDirection: "column",
      p: "3",
      pt: "5",
      borderRadius: "md",
      minH: "28",
      whiteSpace: "wrap",
      ...props,
      children: [
        /* @__PURE__ */ jsx(Skeleton, { borderRadius: "full", children: /* @__PURE__ */ jsx(Circle, { size: "10" }) }),
        /* @__PURE__ */ jsx(Skeleton, { h: "4", w: "3/5" })
      ]
    }
  );
});

export { IntegrationItemSkeleton };
