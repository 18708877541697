"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.3.0
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { DecoratorNode } from 'lexical';
import { EditorTag } from '../components/EditorTag.mjs';

var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
const NODE_DATA_ATTR = "data-editor-tag";
class EditorTagNode extends DecoratorNode {
  constructor(value, key) {
    super(key);
    __publicField(this, "__value");
    __publicField(this, "__id");
    __publicField(this, "__autoOpen");
    this.__value = value.value;
    this.__autoOpen = value.autoOpen;
    this.__id = value.id;
  }
  static getType() {
    return "editor-tag-node";
  }
  static clone(node) {
    return new EditorTagNode(
      { value: node.__value, autoOpen: node.__autoOpen, id: node.__id },
      node.__key
    );
  }
  createDOM() {
    const element = document.createElement("span");
    element.setAttribute(NODE_DATA_ATTR, "true");
    element.setAttribute("contenteditable", "false");
    return element;
  }
  updateDOM() {
    return false;
  }
  setValue(value) {
    const writable = this.getWritable();
    writable.__value = value;
  }
  decorate() {
    return /* @__PURE__ */ jsx(
      EditorTag,
      {
        value: this.__value,
        internalKey: this.__key,
        autoOpen: this.__autoOpen
      }
    );
  }
  static importJSON(serializedNode) {
    const node = $createEditorTagNode(serializedNode.value);
    return node;
  }
  exportJSON() {
    return {
      type: EditorTagNode.getType(),
      value: this.__value,
      version: 1
    };
  }
  get id() {
    return this.__id;
  }
  get value() {
    return this.__value;
  }
}
function $createEditorTagNode(value) {
  return new EditorTagNode(value);
}
function $isEditorTagNode(node) {
  return node instanceof EditorTagNode;
}

export { $createEditorTagNode, $isEditorTagNode, EditorTagNode, NODE_DATA_ATTR };
