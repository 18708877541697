"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.3.0
 */
'use client';

import { getPatternStyles, patternFns } from '../helpers.mjs';
import { css } from '../css/css.mjs';

const visuallyHiddenConfig = {
  transform(props) {
    return {
      srOnly: true,
      ...props
    };
  }
};
const getVisuallyHiddenStyle = (styles = {}) => {
  const _styles = getPatternStyles(visuallyHiddenConfig, styles);
  return visuallyHiddenConfig.transform(_styles, patternFns);
};
const visuallyHidden = (styles) => css(getVisuallyHiddenStyle(styles));
visuallyHidden.raw = getVisuallyHiddenStyle;

export { getVisuallyHiddenStyle, visuallyHidden };
