"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.3.0
 */
'use client';

import { getPatternStyles, patternFns } from '../helpers.mjs';
import '../css/css.mjs';

const flexConfig = {
  transform(props) {
    const { direction, align, justify, wrap: wrap2, basis, grow, shrink, ...rest } = props;
    return {
      display: "flex",
      flexDirection: direction,
      alignItems: align,
      justifyContent: justify,
      flexWrap: wrap2,
      flexBasis: basis,
      flexGrow: grow,
      flexShrink: shrink,
      ...rest
    };
  }
};
const getFlexStyle = (styles = {}) => {
  const _styles = getPatternStyles(flexConfig, styles);
  return flexConfig.transform(_styles, patternFns);
};

export { getFlexStyle };
