"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.3.0
 */
'use client';

import { filterOptionsByLabel } from '../ComboBox/options-factories/utils.mjs';

var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, key + "" , value);
class DataLinkTablesOptionsFactory {
  constructor(args) {
    this.args = args;
    __publicField(this, "cachedOptions", null);
  }
  async getOptions({ input }) {
    const options = await this.getCachedOptions();
    return filterOptionsByLabel(options, input);
  }
  async getCachedOptions() {
    if (this.cachedOptions === null) {
      const { client, connectionId, isAdminMode } = this.args;
      if (isAdminMode) {
        const responsePage = await client.dataLinkTables.find();
        this.cachedOptions = responsePage.items.map((item) => ({
          label: item.name,
          value: item.key
        }));
      } else {
        const responsePage = await client.dataLinkTableInstances.find({
          connectionId
        });
        this.cachedOptions = responsePage.items.filter((i) => !!i.dataLinkTable).map((item) => ({
          label: item?.dataLinkTable?.name,
          value: item?.dataLinkTable?.key
        }));
      }
    }
    return this.cachedOptions;
  }
}

export { DataLinkTablesOptionsFactory };
