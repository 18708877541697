/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.3.0
 */
'use client';

import styleInject from 'style-inject';

var css_248z = ".ScalarArray-module_wrapper__wxaVk {\n    width: 100%;\n    margin-left: -1.25rem;\n    padding-bottom: 0.5rem\n}\n";
var classes = {"wrapper":"ScalarArray-module_wrapper__wxaVk"};
styleInject(css_248z);

export { classes as default };
