"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.3.0
 */
'use client';

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getRoot } from 'lexical';
import { INSERT_EDITOR_TAG_COMMAND } from './plugins/commands.mjs';

const $isEditorEmpty = () => {
  const children = $getRoot().getChildren();
  const paragraph = children[0];
  return paragraph.getChildrenSize() === 0;
};
const useEditor = () => {
  const [editor] = useLexicalComposerContext();
  function pasteTag({
    value,
    autoOpen,
    allowEditorSearchClear = false
  }) {
    if (allowEditorSearchClear) {
      editor.update(() => {
        if (!$isEditorEmpty()) return;
        $getRoot().clear();
      });
    }
    editor.dispatchCommand(INSERT_EDITOR_TAG_COMMAND, {
      id: (/* @__PURE__ */ new Date()).getTime().toString(),
      value,
      autoOpen
    });
  }
  return { editor, pasteTag };
};

export { useEditor };
