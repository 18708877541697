"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.3.0
 */
'use client';

import '../../styled-system/css/css.mjs';
import { cva } from '../../styled-system/css/cva.mjs';
import '../../styled-system/helpers.mjs';

const scrollShadow = cva({
  base: {
    background: `
      linear-gradient(#ffffff 33%, rgba(255, 255, 255, 0)),
      linear-gradient(rgba(255, 255, 255, 0), #ffffff 66%) 0 100%,
      radial-gradient(farthest-side at 50% 0, rgba(34, 34, 34, 0.5), rgba(0, 0, 0, 0)),
      radial-gradient(farthest-side at 50% 100%, rgba(34, 34, 34, 0.5), rgba(0, 0, 0, 0)) 0 100%
    `,
    backgroundColor: "transparent",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "local, local, scroll, scroll",
    backgroundSize: "100% 15px, 100% 15px, 100% 2px, 100% 2px"
  }
});

export { scrollShadow };
