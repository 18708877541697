"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.3.0
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { createContext, useContext, useState } from 'react';
import deepEqual from 'fast-deep-equal';
import { useComboBoxDropdownContext } from '../context/combobox-dropdown-context.mjs';

const NavigationContext = createContext({
  currentItem: null,
  moveToNextItem: () => null,
  moveToPreviousItem: () => null,
  onOptionsListChange: () => null
});
function NavigationContextProvider({
  children
}) {
  const [currentItem, setCurrentItem] = useState(null);
  const { isOptionSelected, drillDownStackTop } = useComboBoxDropdownContext();
  const moveToPreviousItem = (options, currentItem2) => {
    if (currentItem2 === "drill-up") {
      return;
    }
    const onlyOptions = getOnlyOptions(options);
    if (!currentItem2) {
      setCurrentItem(onlyOptions[0]);
      return;
    }
    const idx = getOptionIndex(currentItem2, onlyOptions);
    if (idx === 0 && !!drillDownStackTop) {
      setCurrentItem("drill-up");
      return;
    }
    if (idx > 0) {
      const currentOption = onlyOptions[idx - 1];
      setCurrentItem(currentOption);
    }
  };
  const moveToNextItem = (options, currentItem2) => {
    const onlyOptions = getOnlyOptions(options);
    if (onlyOptions.length === 0) {
      setCurrentItem(null);
      return;
    }
    if (!currentItem2 || currentItem2 === "drill-up") {
      setCurrentItem(onlyOptions[0]);
      return;
    }
    const idx = getOptionIndex(currentItem2, onlyOptions);
    if (idx < onlyOptions.length - 1) {
      const currentOption = onlyOptions[idx + 1];
      setCurrentItem(currentOption);
    }
  };
  const onOptionsListChange = (options) => {
    const onlyOptions = getOnlyOptions(options);
    if (!onlyOptions?.length) {
      return;
    }
    if (!currentItem || currentItem === "drill-up") {
      setCurrentItem(onlyOptions[0]);
      return;
    }
    if (getOptionIndex(currentItem, onlyOptions) == -1) {
      const selectedOption = options.find(isOptionSelected);
      if (selectedOption) {
        setCurrentItem(selectedOption);
      } else {
        const onlyOptions2 = getOnlyOptions(options);
        setCurrentItem(onlyOptions2[0]);
      }
    }
  };
  return /* @__PURE__ */ jsx(
    NavigationContext.Provider,
    {
      value: {
        currentItem,
        moveToNextItem,
        moveToPreviousItem,
        onOptionsListChange
      },
      children
    }
  );
}
function useNavigationContext() {
  return useContext(NavigationContext);
}
const makeOptionComparable = (option) => {
  return option && {
    ...option,
    label: option.searchLabel ?? option.label?.toString()
  };
};
const getOnlyOptions = (options) => options.filter((o) => !o.isSection);
function getOptionIndex(option, options) {
  return options.findIndex(
    (o) => deepEqual(makeOptionComparable(o), makeOptionComparable(option))
  );
}

export { NavigationContextProvider, useNavigationContext };
