"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.3.0
 */
'use client';

import { jsx, Fragment } from 'react/jsx-runtime';
import { DropdownUI } from '../../../../DropdownUI/index.mjs';
import { OptionsList } from '../../OptionsList.mjs';

function FormulasCategoryContent({
  onOptionSelect
}) {
  return /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx(DropdownUI.Panel, { children: /* @__PURE__ */ jsx(OptionsList, { onOptionSelect }) }) });
}

export { FormulasCategoryContent };
