import { HTMLAttributes, PropsWithChildren } from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'

import { IconButton, IconButtonProps } from 'components/Button/IconButton'
import { Select, Combobox, SelectProps, ComboboxProps } from 'components/Select'
import { Tooltip } from 'components/Tooltip'
import { sva, cx } from 'styled-system/css'
import { Flex, FlexProps } from 'styled-system/jsx'
import { Button, ButtonProps } from 'ui-kit/button'
import { Icon } from 'ui-kit/icon'
import { IconButton as UiKitIconButton } from 'ui-kit/icon-button'
import { Text, TextProps } from 'ui-kit/text'
import { createSlotRecipeContext } from 'ui-kit/utils/create-slot-recipe-context'

const controlGroupRecipe = sva({
  className: 'controlGroup',
  slots: ['wrapper', 'button', 'iconLink', 'select', 'text'],
  base: {
    wrapper: {
      // eslint-disable-next-line @pandacss/no-unsafe-token-fn-usage, @pandacss/no-dynamic-styling -- need to pass the token to the CSS variable
      ['--control-group-border-radius']: '{radii.l3}',
      gap: 0,
      alignItems: 'stretch',
      borderColor: 'colorPalette.a5',
      borderWidth: 1,
      borderRadius: 'var(--control-group-border-radius)',
      backgroundColor: 'bg.subtle',
      colorPalette: 'neutral',
    },
    button: {
      flexGrow: 0,
      flexShrink: 0,
      borderRadius: 'calc(var(--control-group-border-radius) - 1px)',
      '&:not(:first-child)': {
        borderLeftRadius: 'none',
        // eslint-disable-next-line @pandacss/no-hardcoded-color
        borderColor: 'inherit',
        borderLeftWidth: 1,
      },
      '&:not(:last-child)': {
        borderRightRadius: 'none',
      },
    },
    iconLink: {
      position: 'absolute',
      inset: 0,
    },
    select: {
      '& [data-scope="select"][data-part="trigger"]': {
        textWrap: 'nowrap',
        borderRadius: 'calc(var(--control-group-border-radius) - 1px)',
      },
      '&:not(:first-child)': {
        // eslint-disable-next-line @pandacss/no-hardcoded-color
        borderColor: 'inherit',
        borderLeftWidth: 1,
        borderStyle: 'solid',
        '& [data-scope="select"][data-part="trigger"]': {
          borderLeftRadius: 'none',
        },
      },
      '&:not(:last-child)': {
        '& [data-scope="select"][data-part="trigger"]': {
          borderRightRadius: 'none',
        },
      },
    },
    text: {
      alignContent: 'center',
      truncate: true,
      flexGrow: 0,
      flexShrink: 0,
      '&:not(:first-child)': {
        // eslint-disable-next-line @pandacss/no-hardcoded-color
        borderColor: 'inherit',
        borderLeftWidth: 1,
      },
    },
  },
  variants: {
    size: {
      dense: {
        text: {
          paddingInline: '2.5',
        },
      },
      sm: {
        text: {
          paddingInline: '2.5',
        },
      },
      md: {
        text: {
          paddingInline: '3',
        },
      },
      lg: {
        text: {
          paddingInline: '3.5',
        },
      },
    },
  },
  defaultVariants: {
    size: 'md',
  },
})

const context = createSlotRecipeContext(controlGroupRecipe)

const Root = context.withProvider<FlexProps>(Flex, 'wrapper', {
  forwardVariantsToSlots: ['size'],
  props: {
    width: 'fit-content',
  },
})
const ButtonItem = context.withContext<
  ButtonProps & HTMLAttributes<HTMLButtonElement>
>(Button, 'button', {
  variant: 'ghost',
})
const IconButtonItem = context.withContext<IconButtonProps>(
  IconButton,
  'button',
  {
    variant: 'ghost',
  },
)
const SelectItem = context.withContext<SelectProps>(Select, 'select', {
  variant: 'ghost',
})
const ComboboxItem = context.withContext<ComboboxProps>(Combobox, 'select', {
  variant: 'ghost',
})
const TextItem = context.withContext<TextProps>(Text, 'text', {
  as: 'span',
  textStyle: 'md',
  color: 'fg.muted',
})

function LinkItem({
  children,
  className,
  ...props
}: Omit<NavLinkProps, 'className'> & { className?: string }) {
  const { forwardedProps, slotStyles } = context.use()

  return (
    <Button
      asChild
      variant='ghost'
      {...forwardedProps}
      className={cx(slotStyles.button, className)}
    >
      <NavLink {...props}>{children}</NavLink>
    </Button>
  )
}

function IconLinkItem({
  children,
  tooltip,
  className,
  ...props
}: PropsWithChildren<
  Omit<NavLinkProps, 'className' | 'children'> & {
    className?: string
    tooltip: string
  }
>) {
  const { forwardedProps, slotStyles } = context.use()

  return (
    <Tooltip tooltip={tooltip}>
      <UiKitIconButton
        variant='ghost'
        className={cx(slotStyles.button, className)}
        {...forwardedProps}
      >
        <span>
          <NavLink className={cx(slotStyles.iconLink)} {...props} />
          <Icon>{children}</Icon>
        </span>
      </UiKitIconButton>
    </Tooltip>
  )
}

const ControlGroup = {
  Root,
  Button: ButtonItem,
  Select: SelectItem,
  Combobox: ComboboxItem,
  IconButton: IconButtonItem,
  Text: TextItem,
  Link: LinkItem,
  IconLink: IconLinkItem,
}

export { ControlGroup }
