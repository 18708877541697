"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.3.0
 */
'use client';

import { FormulaListOptionsFactory } from './formula.mjs';
import { DropdownCategoryType } from '../types.mjs';
import { CustomFieldOptionsFactory } from './custom-field.mjs';
import { filterOptionsByLabel } from './utils.mjs';
import { VariableOptionFactory } from './variable.mjs';
import { renderCategories } from '../Dropdown/categoryContentMapping.mjs';

var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, key + "" , value);
const EXCLUDED_CATEGORIES = [
  DropdownCategoryType.OPTIONS,
  DropdownCategoryType.LOOKUP_OPTIONS
];
class RootOptionFactory {
  constructor(categories) {
    __publicField(this, "categories");
    if (!categories && categories === null) {
      this.categories = [];
    } else {
      this.categories = categories;
    }
  }
  async getOptions({
    input,
    spec,
    variablesSchema,
    editableVariablesSchemaLocators
  }) {
    if (this.categories.length === 1) {
      const [category] = this.categories;
      const childCategoryFactory = this.getChildFactoryForCategory({
        category,
        schema: variablesSchema,
        editableSchemaLocators: editableVariablesSchemaLocators
      });
      if (childCategoryFactory) {
        return childCategoryFactory?.getOptions({
          input,
          spec,
          variablesSchema,
          editableVariablesSchemaLocators
        });
      }
    }
    return this.getOptionsSync({
      input,
      spec,
      variablesSchema,
      editableVariablesSchemaLocators
    });
  }
  getOptionsSync({
    input,
    variablesSchema,
    editableVariablesSchemaLocators
  }) {
    const options = [];
    if (input) {
      return options;
    }
    this.categories?.filter((category) => !EXCLUDED_CATEGORIES.includes(category))?.map((category) => {
      const factory = this.getChildFactoryForCategory({
        category,
        schema: variablesSchema,
        editableSchemaLocators: editableVariablesSchemaLocators
      });
      options.push({
        label: renderCategories[category].title,
        iconType: renderCategories[category].icon,
        drillDownOptionFactory: factory,
        childCategory: category
      });
    });
    return filterOptionsByLabel(options, input);
  }
  getChildFactoryForCategory({
    category,
    schema,
    editableSchemaLocators
  }) {
    switch (category) {
      case DropdownCategoryType.VARIABLE:
        return new VariableOptionFactory({
          schema,
          editableSchemaLocators
        });
      case DropdownCategoryType.FORMULA:
        return new FormulaListOptionsFactory();
      case DropdownCategoryType.CUSTOM:
        return new CustomFieldOptionsFactory();
      default:
        return void 0;
    }
  }
}

export { RootOptionFactory };
