"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.3.0
 */
'use client';

import { forwardRef, createElement } from 'react';
import { splitProps } from '../helpers.mjs';
import { getCircleStyle } from '../patterns/circle.mjs';
import { styled } from './factory.mjs';

const Circle = /* @__PURE__ */ forwardRef(function Circle2(props, ref) {
  const [patternProps, restProps] = splitProps(props, ["size"]);
  const styleProps = getCircleStyle(patternProps);
  const mergedProps = { ref, ...styleProps, ...restProps };
  return createElement(styled.div, mergedProps);
});

export { Circle };
