"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.3.0
 */
'use client';

const preventFocusShift = (e) => {
  e.preventDefault();
};

export { preventFocusShift };
