"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.3.0
 */
'use client';

import { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $insertNodes, COMMAND_PRIORITY_EDITOR } from 'lexical';
import { INSERT_EDITOR_TAG_COMMAND } from './commands.mjs';
import { EditorTagNode, $createEditorTagNode } from '../nodes/EditorTagNode.mjs';

function EditorTagPlugin() {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    if (!editor.hasNodes([EditorTagNode])) {
      throw new Error("EditorTagPlugin: EditorTagNode not registered on editor");
    }
    editor.registerCommand(
      INSERT_EDITOR_TAG_COMMAND,
      (payload) => {
        editor.update(() => {
          $insertNodes([$createEditorTagNode(payload)]);
        });
        return true;
      },
      COMMAND_PRIORITY_EDITOR
    );
  }, [editor]);
  return null;
}

export { EditorTagPlugin };
