"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.3.0
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { clsx } from 'clsx';
import { $getNodeByKey } from 'lexical';
import { ComboBox } from '../../../ComboBox/ComboBox.mjs';
import { useComboBox } from '../../../ComboBox/context/combobox-context.mjs';
import { $isEditorTagNode } from '../nodes/EditorTagNode.mjs';
import useDataBuilder from '../../../DataBuilder/data-builder-context.mjs';
import { usIsFieldFrozen } from '../../../DataBuilder/hooks.mjs';
import classes from '../../inputs.module.css.mjs';

const EditorTag = ({
  internalKey,
  value,
  autoOpen
}) => {
  const { isReadOnly } = useDataBuilder();
  const isFrozenField = usIsFieldFrozen();
  const [editor] = useLexicalComposerContext();
  const { variablesSchema } = useComboBox();
  const handleChange = (value2) => {
    editor.update(() => {
      const node = $getNodeByKey(internalKey);
      if ($isEditorTagNode(node)) {
        if (value2 === void 0) {
          node.remove();
        } else {
          node.setValue(value2);
        }
      }
    });
  };
  return /* @__PURE__ */ jsx(
    "div",
    {
      className: clsx(classes.tagWrapper, {
        [classes.tagWrapper__readonly]: isReadOnly || isFrozenField
      }),
      children: /* @__PURE__ */ jsx(
        ComboBox,
        {
          className: classes.innerTag,
          value,
          schema: { type: "string" },
          variablesOnly: true,
          disabled: isReadOnly || isFrozenField,
          variablesSchema,
          onChange: handleChange,
          autoOpen
        }
      )
    }
  );
};

export { EditorTag };
