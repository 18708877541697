"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.3.0
 */
'use client';

import { getReferenceCollectionPointerForSchema } from '@integration-app/sdk';

async function getLookupCollection({
  schema,
  client,
  connectionId,
  integrationId
}) {
  if (!schema) {
    return void 0;
  }
  const { key, parameters } = getReferenceCollectionPointerForSchema(schema) ?? {};
  if (!key) {
    return void 0;
  }
  if (!connectionId && !integrationId) {
    return void 0;
  }
  let result = void 0;
  try {
    if (connectionId) {
      result = await client.connection(connectionId).dataCollection(key, parameters).get();
    } else if (integrationId && key) {
      result = await client.integration(integrationId).getDataCollection(key);
    }
  } catch (e) {
    console.error(`fetching collection '${key}' returns error`, e);
  }
  return result;
}

export { getLookupCollection };
