"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.3.0
 */
'use client';

import { forwardRef, createElement } from 'react';
import { splitProps } from '../helpers.mjs';
import { getFlexStyle } from '../patterns/flex.mjs';
import { styled } from './factory.mjs';

const Flex = /* @__PURE__ */ forwardRef(function Flex2(props, ref) {
  const [patternProps, restProps] = splitProps(props, ["align", "justify", "direction", "wrap", "basis", "grow", "shrink"]);
  const styleProps = getFlexStyle(patternProps);
  const mergedProps = { ref, ...styleProps, ...restProps };
  return createElement(styled.div, mergedProps);
});

export { Flex };
